import { render, staticRenderFns } from "./TrashedRoles.vue?vue&type=template&id=eeee7800&lang=html&"
import script from "./TrashedRoles.vue?vue&type=script&lang=js&"
export * from "./TrashedRoles.vue?vue&type=script&lang=js&"
import style0 from "./TrashedRoles.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports